import axiosInstance from "./axiosInstance";

const createItem = (newitem) => {
  return axiosInstance.post("itemlists/", newitem);
};

const getItem = (id) => {
  return axiosInstance.get(`itemlists/${id}/`);
};

const updateItem = (id, item) => {
  return axiosInstance.patch(`itemlists/${id}/`, item);
};

const deleteItem = (id) => {
  return axiosInstance.delete(`itemlists/${id}/`);
};

const getProductListItems = () => {
  return axiosInstance.get("list-itemlist/");
};

const getProductCodeSuggestion = (query) => {
  return axiosInstance.get(`filter-products/?search=${query}`);
};

export { getProductListItems, createItem, getProductCodeSuggestion, getItem, updateItem, deleteItem };  
