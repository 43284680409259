import React, { useState, useEffect, useContext } from "react";
import {
  createProduct,
  updateProduct,
  getChoices,
  getSafetyList,
  getProductById,
  getDosageList,
  uploadProductImage,
} from "../../services/genericService";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./GenericForm.css";
import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";
import { toast } from "react-toastify";

const GenericForm = ({ active }) => {
  const [product, setProduct] = useState({
    name: "",
    family: "",
    category: "",
    division: "",
    type: "",
    sub_category: "",
    image: null,
    use: "",
    mechanism_of_action: "",
    handling_warnings: "",
    safety: [],
    adverse_reactions: "",
    dosage: [],
    cautions: "",
    drug_interactions: "",
    availability: "",
    drug_interactions_comments: "",
    safety_comments: [],
    detailing_dosing_comments: [],
  });

  const [choices, setChoices] = useState({
    families: [],
    categories: [],
    divisions: [],
    types: [],
    subCategories: [],
  });

  const [safetyOptions, setSafetyOptions] = useState([]);
  const [detailingDosingOptions, setDetailingDosingOptions] = useState([]);
  const [openHierarchy, setOpenHierarchy] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedProduct = location.state?.product;
  const { expanded } = useContext(SidebarContext);

  useEffect(() => {
    fetchChoices();
    fetchSafetyOptions();
    fetchDetailingDosingOptions();
    if (selectedProduct) {
      getProduct(selectedProduct.id);
    }
  }, [selectedProduct]);

  const getProduct = async (id) => {
    try {
      const response = await getProductById(id);
      const productData = response.data;

      setProduct((prevProduct) => ({
        ...prevProduct,
        ...productData,
        family:
          productData.sub_category.type.division.category.family?.id || "",
        category: productData.sub_category.type.division.category?.id || "",
        division: productData.sub_category.type.division?.id || "",
        type: productData.sub_category.type?.id || "",
        sub_category: productData.sub_category?.id || "",
        image: null,
        safety: [...(productData.safety || []).map((safety) => safety)],
        dosage: [...(productData.dosage || []).map((dosage) => dosage)],
        drug_interactions_comments:
          productData.drug_interactions_comments || "",
        safety_comments: [
          ...(productData.safety || []).map((safety) => safety.comment),
        ],
        detailing_dosing_comments: [
          ...(productData.dosage || []).map((dosage) => dosage.comment),
        ],
      }));
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const fetchChoices = async () => {
    try {
      const response = await getChoices();
      setChoices(response);
    } catch (error) {
      console.error("Error fetching choices:", error);
    }
  };

  const fetchSafetyOptions = async () => {
    try {
      const response = await getSafetyList();
      setSafetyOptions(response.data);
    } catch (error) {
      console.error("Error fetching safety options:", error);
    }
  };

  const fetchDetailingDosingOptions = async () => {
    try {
      const response = await getDosageList();
      setDetailingDosingOptions(response.data);
    } catch (error) {
      console.error("Error fetching detailing dosing options:", error);
    }
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleImageChange = (e) => {
    setProduct({ ...product, image: e.target.files[0] });
  };

  const handleSafetyChange = (event) => {
    const {
      target: { value },
    } = event;

    // Parse the value if it's a string (if stringified)
    const parsedSafety = typeof value === "string" ? JSON.parse(value) : value;
    const updatedSafety = parsedSafety.map((safetyId) => {
      const existingSafety = product.safety.find(
        (item) => item.safety === safetyId
      );
      return existingSafety || { safety: safetyId, comment: "" };
    });

    setProduct((prevProduct) => ({
      ...prevProduct,
      safety: updatedSafety,
    }));
  };

  const handleDetailingDosingChange = (event) => {
    const {
      target: { value },
    } = event;

    // Parse the value if it's a string (if stringified)
    const parsedDosage = typeof value === "string" ? JSON.parse(value) : value;

    const updatedDosage = parsedDosage.map((dosageId) => {
      const existingDosage = product.dosage.find(
        (item) => item.dosage === dosageId
      );
      return existingDosage || { dosage: dosageId, comment: "" };
    });

    setProduct((prevProduct) => ({
      ...prevProduct,
      dosage: updatedDosage,
    }));
  };

  const handleCommentChange = (index, value, type) => {
    if (type === "drug_interaction") {
      setProduct({ ...product, drug_interactions_comments: value });
    } else if (type === "safety") {
      const updatedSafety = [...product.safety];
      if (updatedSafety[index]) {
        updatedSafety[index] = { ...updatedSafety[index], comment: value };
      }
      setProduct({ ...product, safety: updatedSafety });
    } else if (type === "dosage") {
      const updatedDosage = [...product.dosage];
      if (updatedDosage[index]) {
        updatedDosage[index] = { ...updatedDosage[index], comment: value }; // Update the comment for dosage
      }
      setProduct({ ...product, dosage: updatedDosage });
    }
  };

  const handleProductSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    try {
      let response;
      if (selectedProduct) {
        // Update product logic
        response = await updateProduct(selectedProduct.id, product); // Send JSON product data

        // Then, if there is an image, submit it as FormData
        if (product.image) {
          const formData = new FormData();
          formData.append("image", product.image);

          await uploadProductImage(response.data.id, formData); // Upload the image separately
        }
        toast.success("Product updated successfully!"); // Trigger toast for successful update
      } else {
        // Create product logic
        response = await createProduct(product); // Send JSON product data

        // Then, if there is an image, submit it as FormData
        if (product.image) {
          const formData = new FormData();
          formData.append("image", product.image);

          await uploadProductImage(response.data.id, formData); // Upload the image separately
        }
        toast.success("Product created successfully!"); // Trigger toast for successful creation
      }

      // Reset product state after successful submission
      setProduct({
        name: "",
        family: "",
        category: "",
        division: "",
        type: "",
        sub_category: "",
        image: null,
        use: "",
        mechanism_of_action: "",
        handling_warnings: "",
        safety: [],
        adverse_reactions: "",
        dosage: [],
        cautions: "",
        drug_interactions: "",
        availability: "",
        drug_interactions_comments: "",
        safety_comments: [],
        detailing_dosing_comments: [],
      });

      navigate("/generic-list");
    } catch (error) {
      console.error(
        "Error creating/updating product:",
        error.response?.data || error.message
      );

      toast.error(
        "Failed to submit product. Please check the input and try again."
      );
    }
  };
  const filteredCategories = choices.categories.filter(
    (category) => category.family.id === product.family
  );
  const filteredDivisions = choices.divisions.filter(
    (division) => division.category.id === product.category
  );
  const filteredTypes = choices.types.filter(
    (type) => type.division.id === product.division
  );
  const filteredSubCategories = choices.subCategories.filter(
    (subCategory) => subCategory.type.id === product.type
  );

  return (
    <div className="form-layout">
      <Sidebar active={active} />

      <div className={`container ${expanded ? "expanded" : "collapsed"}`}>
        <Typography
          variant="h4"
          gutterBottom
          className="generic-form-header"
          sx={{ mt: 2 }}
        >
          {selectedProduct ? "Edit Product" : "Add Generic"}
        </Typography>
        <Card elevation={3} className="generic-form-card">
          <CardContent>
            <form onSubmit={handleProductSubmit} encType="multipart/form-data">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <label>Name</label>
                  <TextField
                    fullWidth
                    name="name"
                    value={product.name}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    className="generic-form-textfield"
                  />
                </Grid>

                <Grid item xs={12} md={6} display="flex" alignItems="flex-end">
                  <Button
                    onClick={() => setOpenHierarchy(true)}
                    endIcon={<ArrowDropDownIcon />}
                    variant="outlined"
                    style={{
                      padding: "11px",
                      width: "650px",
                      borderColor: "#bfbbbb",
                      color: "black",
                      fontWeight: "200",
                    }}
                  >
                    Hierarchy
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <label className="generic-form-label">*Image</label>
                  <input
                    name="image"
                    type="file"
                    onChange={handleImageChange}
                    className="generic-form-file-input"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Safety</label>
                  <Select
                    fullWidth
                    multiple
                    value={product.safety.map((item) => item.safety)} // Extract safety IDs for dropdown value
                    onChange={handleSafetyChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((id) => (
                          <Chip
                            key={id}
                            label={
                              safetyOptions.find((opt) => opt.id === id)
                                ?.name || ""
                            }
                          />
                        ))}
                      </Box>
                    )}
                    className="generic-form-select"
                  >
                    {safetyOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {product.safety.map((safetyItem, index) => (
                    <Box key={index} mt={2}>
                      <TextField
                        fullWidth
                        label={`Comment for ${
                          safetyOptions.find(
                            (opt) => opt.id === safetyItem.safety
                          )?.name || ""
                        }`}
                        variant="outlined"
                        multiline
                        minRows={2}
                        maxRows={10}
                        value={safetyItem.comment || ""}
                        sx={{ marginBottom: "10px" }}
                        onChange={(e) =>
                          handleCommentChange(index, e.target.value, "safety")
                        }
                        className="generic-form-textfield"
                      />
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={12} md={6}>
                  <label>Detailing/Dosing</label>
                  <Select
                    fullWidth
                    multiple
                    value={product.dosage.map((item) => item.dosage)} // Extract dosage IDs for dropdown value
                    onChange={handleDetailingDosingChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((id) => (
                          <Chip
                            key={id}
                            label={
                              detailingDosingOptions.find(
                                (opt) => opt.id === id
                              )?.name || ""
                            }
                          />
                        ))}
                      </Box>
                    )}
                    className="generic-form-select"
                  >
                    {detailingDosingOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {product.dosage.map((dosageItem, index) => (
                    <Box key={index} mt={2}>
                      <TextField
                        fullWidth
                        label={`Comment for ${
                          detailingDosingOptions.find(
                            (opt) => opt.id === dosageItem.dosage
                          )?.name || ""
                        }`}
                        variant="outlined"
                        multiline
                        minRows={2}
                        maxRows={10}
                        value={dosageItem.comment || ""}
                        onChange={(e) =>
                          handleCommentChange(index, e.target.value, "dosage")
                        }
                        className="generic-form-textfield"
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>

              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
                <div className="use">
                  <label>Use</label>
                  <TextField
                    fullWidth
                    name="use"
                    value={product.use}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={10}
                    className="generic-form-textfield"
                  />
                </div>

                <div className="action">
                  <label>Mechanism of Action</label>
                  <TextField
                    fullWidth
                    name="mechanism_of_action"
                    value={product.mechanism_of_action}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={10}
                    className="generic-form-textfield"
                  />
                </div>

                <div className="warning">
                  <label>Handling Warnings</label>
                  <TextField
                    fullWidth
                    name="handling_warnings"
                    value={product.handling_warnings}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={10}
                    className="generic-form-textfield"
                  />
                </div>

                <div className="reactions">
                  <label>Adverse Reactions</label>
                  <TextField
                    fullWidth
                    name="adverse_reactions"
                    value={product.adverse_reactions}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={10}
                    className="generic-form-textfield"
                  />
                </div>

                <div className="cautions">
                  <label>Cautions</label>
                  <TextField
                    fullWidth
                    name="cautions"
                    value={product.cautions}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={10}
                    className="generic-form-textfield"
                  />
                </div>
                <div className="availability">
                  <label>Availability</label>
                  <TextField
                    fullWidth
                    name="availability"
                    value={product.is_available}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={10}
                    className="generic-form-textfield"
                  />
                </div>

                <div className="drugs">
                  <label>Drug Interactions</label>
                  <TextField
                    fullWidth
                    name="drug_interactions"
                    value={product.drug_interactions}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={10}
                    className="generic-form-textfield"
                  />
                </div>
                <div className="comments">
                  {(product.drug_interactions || "")
                    .split(",")
                    .map((interaction, index) => (
                      <Box key={index} mt={2}>
                        <TextField
                          fullWidth
                          label={`Comment for ${interaction.trim()}`}
                          variant="outlined"
                          multiline
                          minRows={2}
                          maxRows={10}
                          value={product.drug_interactions_comments || ""}
                          onChange={(e) =>
                            handleCommentChange(
                              index,
                              e.target.value,
                              "drug_interaction"
                            )
                          }
                          className="generic-form-textfield"
                        />
                      </Box>
                    ))}
                </div>
              </div>

              <div className="grid grid-cols-2  gap-4 mt-3 mb-0">
                <button
                  variant="outlined"
                  onClick={() => navigate("/generic-list")}
                  className="cancel-button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  variant="contained"
                  className="save-button"
                >
                  Save Product
                </button>
              </div>
            </form>
          </CardContent>
        </Card>

        <Dialog
          open={openHierarchy}
          onClose={() => setOpenHierarchy(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Hierarchy</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the appropriate options for Family, Category,
              Division, Type, and Sub-Category.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <label>Family</label>
                <Select
                  fullWidth
                  name="family"
                  value={product.family}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                >
                  <MenuItem value="">Select Family</MenuItem>
                  {choices.families.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Category</label>
                <Select
                  fullWidth
                  name="category"
                  value={product.category}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                  disabled={!product.family}
                >
                  <MenuItem value="">Select Category</MenuItem>
                  {filteredCategories.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Division</label>
                <Select
                  fullWidth
                  name="division"
                  value={product.division}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                  disabled={!product.category}
                >
                  <MenuItem value="">Select Division</MenuItem>
                  {filteredDivisions.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Type</label>
                <Select
                  fullWidth
                  name="type"
                  value={product.type}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                  disabled={!product.division}
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {filteredTypes.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Sub-Category</label>
                <Select
                  fullWidth
                  name="sub_category"
                  value={product.sub_category}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                  disabled={!product.type}
                >
                  <MenuItem value="">Select Sub-Category</MenuItem>
                  {filteredSubCategories.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHierarchy(false)} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default GenericForm;
