import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { SidebarContext } from "../Sidebar/SidebarContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  TextField,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  getCategories,
  getDivisions,
  createDivision,
  deleteDivisions,
} from "../../services/genericService";
import "./Division.css";

const Division = ({ active }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [parent, setParent] = useState("");
  const [divisions, setDivisions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortCriteria, setSortCriteria] = useState("");
  const { expanded } = useContext(SidebarContext);

  useEffect(() => {
    fetchDivisions();
  }, [searchQuery, sortCriteria]);

  const fetchDivisions = async () => {
    setLoading(true);
    try {
      const response = await getDivisions();
      let filteredDivisions = response.data;

      if (searchQuery) {
        filteredDivisions = filteredDivisions.filter(
          (division) =>
            division.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            division.description
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        );
      }

      if (sortCriteria) {
        filteredDivisions = filteredDivisions.sort((a, b) => {
          if (sortCriteria === "name") {
            return a.name.localeCompare(b.name);
          } else if (sortCriteria === "description") {
            return a.description.localeCompare(b.description);
          } else if (sortCriteria === "child_count") {
            return a.childCount - b.childCount;
          } else if (sortCriteria === "parent") {
            return a.parent_details.name.localeCompare(b.parent_details.name);
          }
          return 0;
        });
      }

      setDivisions(filteredDivisions);
    } catch (error) {
      console.error("Error fetching divisions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const newDivision = {
      name,
      description,
      categories : parent,
    };
    try {
      await createDivision(newDivision);
      setName("");
      setDescription("");
      setParent("");
      handleClose();
      fetchDivisions();
    } catch (error) {
      console.error("Error creating division:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDivisions(id);
      fetchDivisions();
    } catch (error) {
      console.error("Failed to delete division:", error);
    }
  };

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          className="box"
        >
          <Typography variant="h4" gutterBottom className="title">
            Divisions
          </Typography>
          <button
            onClick={handleClickOpen}
            variant="contained"
            startIcon={<AddIcon />}
            className="addButton"
          >
            Add Division
          </button>
        </Box>
        <Grid container spacing={2} alignItems="center" mb={3}>
          <Grid item xs={12} sm={6}>
            <input
              placeholder="Search divisions"
              fullWidth
              multiline
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="searchField"
            />
          </Grid>
          <Grid item xs={12} sm={6} className="sort-select-container">
            <select
              fullWidth
              value={sortCriteria}
              onChange={(e) => setSortCriteria(e.target.value)}
              className="searchField"
            >
              <option value="">None</option>
              <option value="name">Name</option>
              <option value="description">Description</option>
              <option value="parent">Parent</option>
              <option value="child_count">Child Count</option>
            </select>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table>
            <TableHead className="tableHead">
              <TableRow>
                <TableCell className="tableCellHeader">ID</TableCell>
                <TableCell className="tableCellHeader">Name</TableCell>
                <TableCell className="tableCellHeader">Description</TableCell>
                <TableCell className="tableCellHeader">Parent</TableCell>
                <TableCell className="tableCellHeader">Child Count</TableCell>
                <TableCell className="tableCellHeader">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : (
                divisions.map((division, index) => (
                  <TableRow key={division.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{division.name || "N/A"}</TableCell>
                    <TableCell>{division.description || "N/A"}</TableCell>
                    <TableCell>{division.category.name || "N/A"}</TableCell>
                    <TableCell>{division.childCount || 0}</TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(division.id)}
                        className="deleteButton"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <h1>Add Division</h1>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add a new division, please enter the division name,
              description, and parent category here.
            </DialogContentText>
            <TextField
              variant="outlined"
              multiline
              label="Name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="dialogField"
            />
            <TextField
              variant="outlined"
              multiline
              label="Description"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="dialogField"
            />
            <Select
              variant="outlined"
              label="Parent"
              fullWidth
              value={parent}
              onChange={(e) => setParent(e.target.value)}
              className="dialogField"
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" onClick={handleSave}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Division;
