import axiosInstance from "./axiosInstance";

const createNewProduct = (newproduct) => {
  return axiosInstance.post("newproduct/", newproduct);
};

const getNewProduct = () => {
  return axiosInstance.get("/list-newproducts/");
};

const updateNewProduct = (id, product) => {
  return axiosInstance.put(`newproduct/${id}/`, product);
};

const deleteNewProduct = (id) => {
  return axiosInstance.delete(`newproduct/${id}/`);
};
const getDetails = (id) => {
  return axiosInstance.get(`newproduct/${id}/`);
};

const exportProductsToCSV = () => {
  return axiosInstance.get("product/export/");
};

const getPracticeMethod = () => {
  return axiosInstance.get("practice_methods/");
};
const getFormulations = () => {
  return axiosInstance.get("formulations/");
};
const getBrandNames = () => {
  return axiosInstance.get("brand_names/");
};
const getDivisions = () => {
  return axiosInstance.get("product_divisions/");
};

const getPresentationUnit = () => {
  return axiosInstance.get("presentation_units/");
};
const uploadProductImage = async (id, formData) => {
  return axiosInstance.post(`upload_image/${id}/`, formData);
};
const getProductCodeSuggestion = (query) => {
  return axiosInstance.get(`filter-generic-products/?search=${query}`);
};
export {
  createNewProduct,
  getNewProduct,
  updateNewProduct,
  deleteNewProduct,
  getDetails,
  exportProductsToCSV,
  getPracticeMethod,
  getFormulations,
  getBrandNames,
  getDivisions,
  getPresentationUnit,
  uploadProductImage,
  getProductCodeSuggestion,
};
