import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import {
  getNewProduct,
  updateNewProduct,
  exportProductsToCSV,
  deleteNewProduct,
} from "../../services/productService";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Inventory,
  Category,
} from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";

// import {
//   Edit as EditIcon,
//   Save as SaveIcon,
//   Cancel as CancelIcon,
//   Inventory,
// } from "@mui/icons-material";
import ProductDetailsModal from "../ProductDetails/ProductDetailsModal";
import "./ViewProducts.css";
import { SidebarContext } from "../Sidebar/SidebarContext";
import { toast } from "react-toastify";

const ViewProducts = ({ active }) => {
  const [products, setProducts] = useState([]);
  const totalProducts = products.length;
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [editProductId, setEditProductId] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { expanded } = useContext(SidebarContext);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await getNewProduct();
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleEditClick = (product) => {
    setEditProductId(product.id);
    setEditedProduct({ ...product });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleSaveClick = async (id) => {
    try {
      await updateNewProduct(id, editedProduct);
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id ? editedProduct : product
        )
      );
      setEditProductId(null);
      setEditedProduct({});
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleCancelClick = () => {
    setEditProductId(null);
    setEditedProduct({});
  };

  const handleRowClick = (product) => {
    navigate(`/product-details/${product.id}`, { state: { product } });
  };

  const handleExportCSV = async () => {
    try {
      const response = await exportProductsToCSV();
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "products.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting CSV:", error);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  //delete item

  // const handleDelete = async (id) => {
  //   try {
  //     await deleteNewProduct(id);
  //     fetchProducts();
  //     toast.success("Product deleted successfully!");
  //   } catch (error) {
  //     console.error("Error deleting product:", error);
  //     toast.error("Error deleting product. Please try again.");
  //   }
  // };
  const handleDelete = async (id) => {
    const confirmDeleteToast = () => {
      const toastId = toast.warn(
        <div>
          <p>Are you sure you want to delete this product?</p>
          <div
            style={{ display: "flex", justifyContent: "space-around", gap: 10 }}
          >
            <button
              onClick={async () => {
                toast.dismiss(toastId); // Dismiss the confirmation toast
                await deleteProductAction(id); // Proceed with deletion
              }}
              style={{
                backgroundColor: "#f44336",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
              }}
            >
              Yes
            </button>
            <button
              onClick={() => toast.dismiss(toastId)} // Cancel deletion
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
              }}
            >
              No
            </button>
          </div>
        </div>,
        {
          autoClose: false, // Keep the toast open until the user interacts
          closeOnClick: false,
        }
      );
    };

    const deleteProductAction = async (id) => {
      try {
        // Call the API to delete the product
        await deleteNewProduct(id);

        // Update the state to remove the deleted product
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== id)
        );

        // Show success toast
        toast.success("Product deleted successfully!", {
          autoClose: 3000,
          position: "top-right",
        });
      } catch (error) {
        console.error("Error deleting product:", error);

        // Show error toast
        toast.error("Failed to delete the product. Please try again.", {
          autoClose: 5000,
          position: "top-right",
        });
      }
    };

    confirmDeleteToast(); // Trigger the confirmation toast
  };

  return (
    <div className="layout">
      <Sidebar active={active} />
      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Typography
          gutterBottom
          sx={{
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.8rem" },
            marginLeft: "10px",
            fontWeight: 100,
            marginTop: "24px",
          }}
        >
          Product List
        </Typography>
        <TableContainer component={Paper} className="table-container">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="filter-box "
          >
            <div className="flex">
              <div className="total-container">
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#5542F6" }}
                >
                  Total ({totalProducts})
                </Typography>
              </div>

              <button
                variant="contained"
                className="export-button"
                onClick={handleExportCSV}
              >
                Export CSV
              </button>
              <button
                variant="contained"
                onClick={() => navigate("/add-new-product")}
                className="add-button"
              >
                + Add Product
              </button>
            </div>
          </Grid>

          <Table className="border-none">
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  className="bg-divbg-100 border-none"
                  sx={{ fontWeight: "bold" }}
                >
                  Sl No
                </TableCell>
                <TableCell
                  className="bg-divbg-100 border-none"
                  sx={{ fontWeight: "bold" }}
                >
                  Product code
                </TableCell>
                <TableCell
                  className="bg-divbg-100 border-none"
                  sx={{ fontWeight: "bold" }}
                >
                  Product Name
                </TableCell>
                <TableCell
                  className="bg-divbg-100 border-none"
                  sx={{ fontWeight: "bold" }}
                >
                  Description
                </TableCell>
                <TableCell
                  className="bg-divbg-100 border-none"
                  sx={{ fontWeight: "bold" }}
                >
                  Sources
                </TableCell>
                <TableCell
                  className="bg-divbg-100 border-none"
                  sx={{ fontWeight: "bold" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="border-none">
              {products.map((product, index) => (
                <TableRow
                  key={product.id}
                  hover
                  onClick={() => handleRowClick(product)}
                >
                  <TableCell className="border-none">{index + 1}</TableCell>
                  <TableCell className="border-none">
                    {editProductId === product.id ? (
                      <TextField
                        name="product_code"
                        value={editedProduct.product_code}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.product_code
                    )}
                  </TableCell>
                  <TableCell className="border-none">
                    {editProductId === product.id ? (
                      <TextField
                        name="product_name"
                        value={editedProduct.product_name}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.product_name
                    )}
                  </TableCell>

                  {/* <TableCell className="border-none">
                    {editProductId === product.id ? (
                      <TextField
                        name="item_id"
                        value={editedProduct.item_id}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.item_id
                    )}
                  </TableCell> */}
                  <TableCell className="border-none">
                    {editProductId === product.id ? (
                      <TextField
                        name="product_description"
                        value={editedProduct.product_description}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.product_description
                    )}
                  </TableCell>
                  <TableCell className="border-none">
                    {editProductId === product.id ? (
                      <TextField
                        name="sources"
                        value={editedProduct.sources}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.sources
                    )}
                  </TableCell>
                  <TableCell className="border-none">
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          // handleEdit(product);
                        }}
                        className="edit-button"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(product.id);
                        }}
                        className="delete-button"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  {/* <TableCell className="border-none">
                    {editProductId === product.id ? (
                      <Box display="flex" justifyContent="center" gap={1}>
                        <Tooltip title="Save">
                          <IconButton
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSaveClick(product.id);
                            }}
                            className="save-icon-button"
                          >
                            <SaveIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <IconButton
                            color="secondary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCancelClick();
                            }}
                            className="cancel-icon-button"
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(product);
                          }}
                          className="edit-icon-button"
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      
                    )}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ProductDetailsModal
          open={modalOpen}
          handleClose={handleCloseModal}
          product={selectedProduct}
        />
      </div>
    </div>
  );
};

export default ViewProducts;
