import React, { useContext, useEffect, useState } from "react";
import { getDetails } from "../../services/productService";
import {
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Card,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";

const ProductDetails = ({ active }) => {
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { expanded } = useContext(SidebarContext);

  useEffect(() => {
    fetchProductDetails(id);
  }, [id]);

  const fetchProductDetails = async (id) => {
    try {
      const response = await getDetails(id);
      setProduct(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product details:", error);
      toast.error("Error fetching product details. Please try again.");
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{ backgroundColor: "#f9f9f9" }}
      >
        <CircularProgress size={50} sx={{ color: "#00796b" }} />
      </Box>
    );
  }

  if (!product) {
    return (
      <Container>
        <Typography
          variant="h6"
          sx={{
            color: "#d32f2f",
            fontWeight: "600",
            textAlign: "center",
            alignSelf: "center",
            marginTop: "20px",
          }}
        >
          Product not found
        </Typography>
      </Container>
    );
  }

  return (
    <div className="layout">
      <Sidebar active={active} />
      <div
        className={`mh-container ${expanded ? "expanded" : "collapsed"}`}
        style={{ margin: "16px auto", maxWidth: "900px" }}
      >
        <Card elevation={3} sx={{ padding: "24px", borderRadius: "12px" }}>
          <Typography
            variant="h5"
            sx={{
              color: "#2c3e50",
              fontWeight: "100",
              mb: 3,
              borderBottom: "1px solid #ddd",
              paddingBottom: "8px",
            }}
          >
            Product Details
          </Typography>
          <Grid container spacing={3} justifyContent={"left"}>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "8px",
                  mb: 4,
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Table className="border-none">
                  <TableHead>
                    <TableRow className="border-none">
                      <TableCell
                        className="bg-divbg-100  border-none"
                        sx={{ fontWeight: "bold", color: "#5542f6" }}
                      >
                        Field
                      </TableCell>
                      <TableCell
                        className="bg-divbg-100 border-none"
                        sx={{ fontWeight: "bold", color: "#5542f6" }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="border-none">
                    {Object.entries(product)
                      .filter(
                        ([key]) =>
                          !["id", "created_at", "updated_at"].includes(key)
                      )
                      .map(([key, value]) => (
                        <TableRow key={key} className="border-none">
                          <TableCell
                            className="border-none"
                            sx={{ fontWeight: "500", color: "#616161" }}
                          >
                            {key.replace(/_/g, " ")}
                          </TableCell>
                          <TableCell className="border-none">
                            {typeof value === "object" && value !== null ? (
                              <Typography sx={{ color: "#37474f" }}>
                                {value.name || JSON.stringify(value, null, 2)}
                              </Typography>
                            ) : (
                              value
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                gap={2}
                mb={4}
              ></Box>
            </Grid>
          </Grid>
        </Card>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ProductDetails;
