import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { TextField, Button, Container, Typography, Grid } from "@mui/material";
import { updateProduct } from "../../src/services/genericService";

const EditProduct = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Retrieve the product data passed from the previous page (Edit)
  const product = location.state?.product;

  // State to store the product data to be edited
  const [formData, setFormData] = useState({
    name: "",
    family: "",
    category: "",
    division: "",
    type: "",
    sub_category: "",
  });

  useEffect(() => {
    if (product) {
      // Pre-fill the form with the product data
      setFormData({
        name: product.name || "",
        family: product.family?.value || "",
        category: product.category?.value || "",
        division: product.division?.value || "",
        type: product.type?.value || "",
        sub_category: product.sub_category?.value || "",
      });
    }
  }, [product]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call the API to update the product
      const updatedProduct = { ...formData };
      await updateProduct(id, updatedProduct); // Assuming you have a service method for this

      // Navigate back to the list page after successful update
      navigate("/generic-list");
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Edit Product
      </Typography>
      {product ? (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Family"
                variant="outlined"
                fullWidth
                name="family"
                value={formData.family}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Category"
                variant="outlined"
                fullWidth
                name="category"
                value={formData.category}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Division"
                variant="outlined"
                fullWidth
                name="division"
                value={formData.division}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Type"
                variant="outlined"
                fullWidth
                name="type"
                value={formData.type}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Sub-Category"
                variant="outlined"
                fullWidth
                name="sub_category"
                value={formData.sub_category}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Typography variant="h6" color="error">
          Product not found.
        </Typography>
      )}
    </Container>
  );
};

export default EditProduct;
