// src/components/Card.js

import React from "react";
import "./Card.css";

const Card = ({ children, className, ...rest }) => {
  return (
    <div className={`Card ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default Card;
