// import React, { useState } from "react";
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Box,
//   IconButton,
//   Tooltip,
// } from "@mui/material";
// import {
//   Home as HomeIcon,
//   Add as AddIcon,
//   List as ListIcon,
//   AccountTree as AccountTreeIcon,
//   Menu as MenuIcon,
// } from "@mui/icons-material";
// import { Link } from "react-router-dom";
// import Logo from "../../components/Assets/Guboy Basic Logo.png";

// const Sidebar = () => {
//   const [collapsed, setCollapsed] = useState(false);

//   const handleToggleCollapse = () => {
//     setCollapsed(!collapsed);
//   };

//   return (
//     <Drawer
//       variant="permanent"
//       sx={{
//         "& .MuiDrawer-paper": {
//           width: collapsed ? 64 : 210,
//           boxSizing: "border-box",
//           background: "#f5f5f5",
//           borderRight: "none",
//           transition: "width 0.3s",
//           overflowX: "hidden",
//         },
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: collapsed ? "center" : "flex-start",
//           padding: "20px 0",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             width: "100%",
//             justifyContent: collapsed ? "center" : "flex-start",
//           }}
//         >
//           {!collapsed && (
//             <>
//               <img
//                 className="logo"
//                 src={Logo}
//                 alt="logo"
//                 style={{ width: "160px", height: "60px", marginLeft: "16px" }}
//               />
//             </>
//           )}
//           <MenuIcon
//             onClick={handleToggleCollapse}
//             sx={{
//               marginLeft: collapsed ? 0 : "5px",
//               marginTop: collapsed ? "20px" : "5px",
//               "&:hover": {
//                 backgroundColor: "rgba(0,0,0,0.1)",
//               },
//             }}
//           >
//             <MenuIcon />
//           </MenuIcon>
//         </Box>
//       </Box>
//       <List>
//         <Tooltip title="Hierarchy Manager" placement="right">
//           <ListItem button component={Link} to="/form-manage">
//             <ListItemIcon>
//               <AccountTreeIcon
//                 sx={{
//                   color: "black",
//                   marginLeft: collapsed ? 0 : "20px",
//                   marginTop: collapsed ? "0" : 0,
//                 }}
//               />
//             </ListItemIcon>
//             {!collapsed && <ListItemText primary="Hierarchy Manager" />}
//           </ListItem>
//         </Tooltip>
//         <Tooltip title="Generic List" placement="right">
//           <ListItem button component={Link} to="/generic-list">
//             <ListItemIcon>
//               <ListIcon
//                 sx={{
//                   color: "black",
//                   marginLeft: collapsed ? 0 : "20px",
//                   marginTop: collapsed ? "10px" : 0,
//                 }}
//               />
//             </ListItemIcon>
//             {!collapsed && <ListItemText primary="Generic List" />}
//           </ListItem>
//         </Tooltip>
//         <Tooltip title="Product List" placement="right">
//           <ListItem button component={Link} to="/view-products">
//             <ListItemIcon>
//               <ListIcon
//                 sx={{
//                   color: "black",
//                   marginLeft: collapsed ? 0 : "20px",
//                   marginTop: collapsed ? "10px" : 0,
//                 }}
//               />
//             </ListItemIcon>
//             {!collapsed && <ListItemText primary="Product List" />}
//           </ListItem>
//         </Tooltip>
//       </List>
//     </Drawer>
//   );
// };

// export default Sidebar;

import "./Sidebar.css";
import HomeIcon from "../../components/Assets/home.svg";
import FileIcon from "../../components/Assets/file.svg";
import TrolleyIcon from "../../components/Assets/trolley.svg";

import { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../Sidebar/SidebarContext";
import MenuIcon from "../../components/Assets/menu.svg";

import classNames from "classnames";

import Logo from "../../components/Assets/Guboy Basic Logo.png";
import { useLocation } from "react-router-dom";

const items = [
  {
    active: "manager",
    text: "Hierarchy Manager",
    image: HomeIcon,
    link: "/form-manage",
  },
  {
    active: "genericlist",
    image: FileIcon,
    text: "Generic List",
    link: "/generic-list",
  },
  {
    active: "products",
    image: TrolleyIcon,
    text: "Product List",
    link: "/view-products",
  },
  {
    active: "listItems",
    image: FileIcon,
    text: "Items",
    link: "/list-items",
  },
];

const Sidebar = ({ active }) => {
  const { expanded, setExpanded } = useContext(SidebarContext);
  const [support, setSupport] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => setExpanded((prev) => !prev);

  const onClose = () => setSupport(false);

  useEffect(() => {
    const handleResize = () => setExpanded(!(window.innerWidth <= 768));
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setExpanded]);

  return (
    <div className="Sidebar">
      <div
        className={classNames(
          "Sidebar__top",
          !expanded && "Sidebar__topNotExpanded"
        )}
      >
        {expanded && <img className="Sidebar__image" src={Logo} alt="logo" />}
        <span className="Sidebar__expand" onClick={toggleSidebar}>
          <img className="altimg" src={MenuIcon} alt="menu icon" />
        </span>
      </div>
      <hr />
      <ul className="mt-4">
        {items.map((item) => (
          <div className="icons" key={item.link}>
            <li
              className={classNames({
                Sidebar__itemActive: location.pathname === item.link,
              })}
            >
              <a href={item.link}>
                <img src={item.image} alt={item.text} />
                {expanded && <span>{item.text}</span>}
              </a>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
