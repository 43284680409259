import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes } from "react-router-dom";
import GenericList from "./Pages/GenericList/GenericList";
import GenericForm from "./Pages/Genericform/GenericForm";
import ManageHierarchy from "./Pages/ManageHierarchy/ManageHierarchy";
import Sidebar from "./Pages/Sidebar/Sidebar";
import ProductForm from "./Pages/ProductForm/ProductForm";
import ViewProducts from "./Pages/ViewProduct/ViewProducts";
import GenericDetails from "./Pages/GenericDetails/GenericDetails";
import { Box } from "@mui/material";
import "./App.css";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import Family from "./Pages/Family/Family";
import Category from "./Pages/Category/Category";
import Types from "./Pages/Types/Types";
import Division from "./Pages/Division/Division";
import Subcategory from "./Pages/Sub-Category/Sub-category";
import { ToastContainer } from "react-toastify";
import { SidebarProvider } from "./Pages/Sidebar/SidebarContext";
import "react-toastify/dist/ReactToastify.css";
import EditProduct from "./Pages/EditProduct";
import ListItems from "./Pages/ListItems/ListItems";
import ItemForm from "./Pages/ItemForm/ItemForm";
import ItemDetails from "./Pages/ItemDetails/ItemDetails";

const theme = createTheme({
  typography: {
    fontSize: 12,
  },
});

const App = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      {/* <Box sx={{ display: "flex" }}>
        <Sidebar
          collapsed={collapsed}
          handleToggleCollapse={handleToggleCollapse}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            transition: "margin-left 0.3s",
            ml: collapsed ? "70px" : "240px",
          }}
        > */}
      <SidebarProvider>
        <Routes>
          <Route path="/" element={<GenericList />} />
          <Route path="/generic-list" element={<GenericList />} />
          <Route path="/details/:id" element={<GenericDetails />} />
          <Route path="/add-product/:id" element={<GenericForm />} />
          <Route
            path="/form-manage"
            element={<ManageHierarchy collapsed={collapsed} />}
          />
          <Route path="/add-product" element={<GenericForm />} />
          <Route path="/add-new-product" element={<ProductForm />} />
          <Route path="/view-products" element={<ViewProducts />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/home/category" element={<Category />} />
          <Route path="/home/types" element={<Types />} />
          <Route path="/home/division" element={<Division />} />
          <Route path="/home/family" element={<Family />} />
          <Route path="/home/subcategory" element={<Subcategory />} />
          <Route path="/edit-product/:id" element={<EditProduct />} />
          <Route path="/list-items" element={<ListItems />} />
          <Route path="/add-new-item" element={<ItemForm />} />
          <Route path="/edit-item/:itemId" element={<ItemForm />} />
          <Route path="/item-details/:id" element={<ItemDetails />} />
        </Routes>
      </SidebarProvider>
      {/* </Box>
      </Box> */}
    </ThemeProvider>
  );
};

export default App;
