import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Box,
  Typography,
  Paper,
  TableBody,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import Sidebar from "../Sidebar/Sidebar";
import { SidebarContext } from "../Sidebar/SidebarContext";
import {
  getProductListItems,
  deleteItem,
} from "../../services/listItemService";
import { toast } from "react-toastify";

const ListItems = ({ active }) => {
  const [products, setProducts] = useState([]);
  const totalItems = products.length;
  const navigate = useNavigate();
  const { expanded } = useContext(SidebarContext);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProductListItems();
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id, event) => {
    event.stopPropagation(); // Prevent row click event

    const confirmDeleteToast = () => {
      const toastId = toast.warn(
        <div>
          <p>Are you sure you want to delete this item?</p>
          <div
            style={{ display: "flex", justifyContent: "space-around", gap: 5 }}
          >
            <button
              onClick={async () => {
                toast.dismiss(toastId); // Dismiss the confirmation toast
                await deleteItemAction(id); // Perform the delete action
              }}
              style={{
                backgroundColor: "#f44336",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
              }}
            >
              Yes
            </button>
            <button
              onClick={() => toast.dismiss(toastId)} // Dismiss the confirmation toast only
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
              }}
            >
              No
            </button>
          </div>
        </div>,
        {
          autoClose: false, // Keep the toast open until user interacts
          closeOnClick: false,
        }
      );
    };

    const deleteItemAction = async (id) => {
      try {
        await deleteItem(id); // Call API to delete the item
        setProducts((prevProducts) =>
          prevProducts.filter((item) => item.id !== id)
        ); // Update state to remove the deleted item

        // Show success toast after deletion
        toast.success("Item deleted successfully!", {
          autoClose: 3000, // Show for 3 seconds
          position: "top-right",
        });
      } catch (error) {
        console.error("Error deleting item:", error.message);
        toast.error("Failed to delete the item. Please try again.", {
          autoClose: 5000,
          position: "top-right",
        });
      }
    };

    confirmDeleteToast();
  };
  // Handle Edit function
  const handleEdit = (id, event) => {
    event.stopPropagation(); // Prevent row click event
    navigate(`/edit-item/${id}`);
  };

  return (
    <div className="layout">
      <Sidebar active={active} />
      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Typography
          gutterBottom
          sx={{
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.8rem" },
            marginLeft: "10px",
            fontWeight: 100,
            marginTop: "24px",
          }}
        >
          Item List
        </Typography>

        <TableContainer component={Paper} className="table-container">
          <Box
            className="header-box flex justify-end"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div className="total-container">
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#5542F6" }}
              >
                Total ({totalItems})
              </Typography>
            </div>
            <button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/add-new-item")}
              className="add-button"
            >
              + Add Item
            </button>
          </Box>
          <Table className="border-none">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell-header">Sl no</TableCell>
                <TableCell className="table-cell-header">Item Code</TableCell>
                <TableCell className="table-cell-header">
                  Product Code
                </TableCell>
                <TableCell className="table-cell-header">Item Name</TableCell>
                <TableCell className="table-cell-header">Price</TableCell>
                <TableCell className="table-cell-header">
                  Batch Number
                </TableCell>
                <TableCell className="table-cell-header">Supplier</TableCell>
                <TableCell className="table-cell-header">Expiry Date</TableCell>
                <TableCell className="table-cell-header">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="border-none">
              {products.map((item, index) => (
                <TableRow
                  key={item.id}
                  hover
                  onClick={() => navigate(`/item-details/${item.id}`)}
                >
                  <TableCell className="border-none">{index + 1}</TableCell>
                  <TableCell className="border-none">
                    {item.item_code || "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {item.product_details?.product_code || "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {item.item_name || "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {item.product_price || "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {item.batch_no || "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {item.supplier || "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {item.expiration_date || "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={(event) => handleEdit(item.id, event)}
                        className="edit-button"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        color="secondary"
                        onClick={(event) => handleDelete(item.id, event)}
                        className="delete-button"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ListItems;
