import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createNewProduct,
  getPracticeMethod,
  getBrandNames,
  getDivisions,
  getFormulations,
  getPresentationUnit,
  uploadProductImage,
  updateNewProduct,
  getProductCodeSuggestion,
} from "../../services/productService";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./ProductForm.css";
import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";

const ProductForm = ({ active }) => {
  const [newProduct, setNewProduct] = useState({
    generic_product: "",
    // generic_product_code: "",
    product_name: "",
    // utility_id: "",
    item_name: "",
    product_description: "",
    sources: "",
    product_image: null,
    practice_method: [],
    formulation: [],
    brand_name: [],
    division: [],
  });
  const { expanded } = useContext(SidebarContext);
  const navigate = useNavigate();
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [practiceMethods, setPracticeMethods] = useState([]);
  const [formulations, setFormulations] = useState([]);
  const [brandNames, setBrandNames] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [unit, setUnit] = useState([]);

  const handleProductSearch = async (event) => {
    const query = event.target.value;
    setNewProduct((prevItem) => ({
      ...prevItem,
      generic_product_code: query,
    }));

    if (query.length >= 1) {
      try {
        const response = await getProductCodeSuggestion(query);
        setProductSuggestions(response.data); // Assuming the API returns an array of product IDs
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching product suggestions:", error);
      }
    } else {
      setProductSuggestions([]);
    }
  };
  useEffect(() => {
    // Fetch practice methods on component mount
    getPracticeMethod()
      .then((response) => {
        setPracticeMethods(response.data); // Assuming the data is in `response.data`
      })
      .catch((error) => {
        console.error("Error fetching practice methods:", error);
      });
    // Fetch formulations
    getFormulations()
      .then((response) => {
        setFormulations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching formulations:", error);
      });

    // Fetch brand names
    getBrandNames()
      .then((response) => {
        setBrandNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching brand names:", error);
      });

    // Fetch divisions
    getDivisions()
      .then((response) => {
        setDivisions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching divisions:", error);
      });
    getPresentationUnit()
      .then((response) => {
        setUnit(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Units:", error);
      });
  }, []);
  const handleDropdownChange = (field) => (e) => {
    setNewProduct((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleImageChange = (e) => {
    setNewProduct({ ...newProduct, product_image: e.target.files[0] });
  };
  //Form validation
  const isValid = () => {
    if (!newProduct.product_name) {
      toast.error("Product Name is required!");
      return false;
    }
    if (!newProduct.product_description) {
      toast.error("Product description is required");
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid()) return;

    const formData = new FormData();

    // Append fields to FormData
    Object.keys(newProduct).forEach((key) => {
      if (Array.isArray(newProduct[key])) {
        formData.append(key, newProduct[key].join(",")); // Convert arrays to comma-separated strings
      } else {
        formData.append(key, newProduct[key] || ""); // Ensure no undefined/null values
      }
    });

    console.log("Submitting product data:", Object.fromEntries(formData));

    try {
      // Create the product
      const response = await createNewProduct(formData);
      toast.success("Product created successfully!");
      navigate("/view-products");
      // Upload the image if it exists

      // Navigate to view products after successful operations
      // navigate("/view-products");
    } catch (error) {
      console.error(
        "Error creating new product:",
        error.response?.data || error.message
      );
      toast.error("Error creating product. Please try again.");
    }
  };

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`container ${expanded ? "expanded" : "collapsed"}`}>
        <Typography variant="h4" gutterBottom className="header">
          Add Product
        </Typography>
        <Card elevation={3} className="card">
          <CardContent>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <label>Product Name</label>
                  <TextField
                    fullWidth
                    name="product_name"
                    value={newProduct.product_name}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <label>Generic Product</label>
                  <TextField
                    fullWidth
                    name="utility_id"
                    value={newProduct.utility_id}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label>Generic Product</label>
                  <Autocomplete
                    options={productSuggestions}
                    getOptionLabel={(option) =>
                      option.generic_product_code || ""
                    } // Use the correct field from the response
                    value={
                      productSuggestions.find(
                        (option) =>
                          option.generic_product_code ===
                          newProduct.generic_product_code
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.generic_product_code === value.generic_product_code
                    }
                    onInputChange={(event, newInputValue) => {
                      handleProductSearch({ target: { value: newInputValue } });
                    }}
                    onChange={(event, newValue) => {
                      setNewProduct((prevItem) => ({
                        ...prevItem,
                        generic_product_code:
                          newValue?.generic_product_code || "",
                        generic_product: newValue?.id || "", // Update product ID
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="generic_product"
                        variant="outlined"
                        fullWidth
                        multiline
                        placeholder="Search Product Code"
                      />
                    )}
                    className="autocomplete mt-1"
                  />
                </Grid>
                <Grid item xs={12}>
                  <label
                    className="generic-form-label"
                    style={{ fontSize: "14px" }} // Adjust the font size
                  >
                    {" "}
                    *Featured Image for product display
                  </label>
                  <input
                    name="product_image"
                    type="file"
                    onChange={handleImageChange}
                    className="generic-form-file-input"
                  />
                </Grid>
                <Grid className="border-none" item xs={12}>
                  <label>Product Description</label>
                  <TextField
                    fullWidth
                    name="product_description"
                    value={newProduct.product_description}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>Sources</label>
                  <TextField
                    fullWidth
                    name="sources"
                    value={newProduct.sources}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    className="textField"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <label>Practice Method</label>
                  <Select
                    fullWidth
                    multiple
                    name="division"
                    value={newProduct.practice_method}
                    onChange={handleDropdownChange("practice_method")}
                    variant="outlined"
                    className="generic-form-select"
                    // disabled={!product.category}
                  >
                    {practiceMethods.map((method, index) => (
                      <MenuItem key={index} value={method.id}>
                        {method.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Formulation</label>
                  <Select
                    fullWidth
                    multiple
                    name="formulation"
                    value={newProduct.formulation}
                    onChange={handleDropdownChange("formulation")}
                    variant="outlined"
                    className="generic-form-select"
                  >
                    {formulations.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} md={6}>
                  <label>Brand Name</label>
                  <Select
                    fullWidth
                    multiple
                    name="brand_name"
                    value={newProduct.brand_name}
                    onChange={handleDropdownChange("brand_name")}
                    variant="outlined"
                    className="generic-form-select"
                  >
                    {brandNames.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} md={6}>
                  <label>Division</label>
                  <Select
                    fullWidth
                    multiple
                    name="division"
                    value={newProduct.division}
                    onChange={handleDropdownChange("division")}
                    variant="outlined"
                    className="generic-form-select"
                  >
                    {divisions.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <div className="grid grid-cols-2  gap-4 mt-3 mb-0">
                <button
                  variant="outlined"
                  onClick={() => navigate("/view-products")}
                  className="cancelButton"
                >
                  Cancel
                </button>
                <button
                  className="saveButton"
                  type="submit"
                  variant="contained"
                >
                  Save Product
                </button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ProductForm;
