import { Typography } from "@mui/material";
import React from "react";

const Data = ({ title, content }) => {
  return (
    <div>
      <div className="flex flex-row justify-between p-1">
        <Typography variant="body1" fontWeight={100} className="pt-1">
          {title}
        </Typography>
        <Typography variant="body1" fontWeight={100} className="pt-1">
          {content}
        </Typography>
      </div>
    </div>
  );
};

export default Data;
