// import React, { useState, useEffect, useContext } from "react";
// import { useParams } from "react-router-dom";
// import Sidebar from "../Sidebar/Sidebar";
// import { SidebarContext } from "../Sidebar/SidebarContext";
// import {
//   Container,
//   Typography,
//   Box,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Button,
// } from "@mui/material";
// import { getProductById } from "../../services/genericService";
// import "./GenericDetails.css";

// const GenericDetails = ({ active }) => {
//   const { id } = useParams();
//   const { expanded } = useContext(SidebarContext);

//   const [entity, setEntity] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchEntity = async () => {
//     try {
//       const response = await getProductById(id);
//       console.log(response.data);
//       setEntity(response.data);
//     } catch (err) {
//       console.error("Failed to fetch entity:", err);
//       setError("Entity not found or unable to fetch details.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchEntity();
//   }, [id]);

//   if (loading) {
//     return (
//       <Container maxWidth="lg">
//         <Typography variant="h5">Loading...</Typography>
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container maxWidth="lg">
//         <Typography variant="h5" color="error">
//           {error}
//         </Typography>
//         <Button variant="contained" onClick={() => window.history.back()}>
//           Go Back
//         </Button>
//       </Container>
//     );
//   }

//   // Function to render safety, dosage, and subcategory names
//   const renderArrayField = (items, fieldName) =>
//     items?.length > 0
//       ? items
//           .map((item) =>
//             typeof item === "object" ? item[fieldName + "_name"] || "N/A" : item
//           )
//           .join(", ")
//       : `No ${fieldName} Available`;

//   const renderSubCategory = (subCategory) => {
//     if (typeof subCategory === "object") {
//       return subCategory.name || "N/A";
//     }
//     return `No Subcategory Available`;
//   };

//   return (
//     <div className="layout">
//       <Sidebar active={active} />
//       <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           mb={3}
//           className="header-box"
//         ></Box>
//         <TableContainer component={Paper} className="table-container">
//           <Typography variant="h4" gutterBottom className="header-text">
//             Entity Details
//           </Typography>
//           <Table className="table">
//             <TableHead className="table-head">
//               <TableRow>
//                 <TableCell className="table-cell-header">Field</TableCell>
//                 <TableCell className="table-cell-header">Value</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {Object.entries(entity)
//                 .filter(
//                   ([key]) =>
//                     ![
//                       "id",
//                       "is_available",
//                       "created_at",
//                       "updated_at",
//                       "image",
//                     ].includes(key)
//                 ) // Exclude unwanted fields
//                 .map(([key, value]) => {
//                   if (key === "safety" || key === "dosage") {
//                     const displayValue = renderArrayField(
//                       value,
//                       key === "safety" ? "safety" : "dosage"
//                     );
//                     return (
//                       <TableRow key={key}>
//                         <TableCell className="table-cell-key">
//                           {key.replace(/_/g, " ")}
//                         </TableCell>
//                         <TableCell>{displayValue}</TableCell>
//                       </TableRow>
//                     );
//                   }

//                   if (key === "sub_category") {
//                     const displayValue = renderSubCategory(value);
//                     return (
//                       <TableRow key={key}>
//                         <TableCell className="table-cell-key">
//                           {key.replace(/_/g, " ")}
//                         </TableCell>
//                         <TableCell>{displayValue}</TableCell>
//                       </TableRow>
//                     );
//                   }

//                   return (
//                     <TableRow key={key}>
//                       <TableCell className="table-cell-key">
//                         {key.replace(/_/g, " ")}
//                       </TableCell>
//                       <TableCell>
//                         {typeof value === "object" ? (
//                           <pre style={{ whiteSpace: "pre-wrap" }}>
//                             {JSON.stringify(value, null, 2)}
//                           </pre>
//                         ) : (
//                           value
//                         )}
//                       </TableCell>
//                     </TableRow>
//                   );
//                 })}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </div>
//     </div>
//   );
// };

// export default GenericDetails;
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { SidebarContext } from "../Sidebar/SidebarContext";
import {
  Container,
  Typography,
  Box,
  Paper,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import { getProductById } from "../../services/genericService";
import "./GenericDetails.css";
import Data from "../../components/Data";

const GenericDetails = ({ active }) => {
  const { id } = useParams();
  const { expanded } = useContext(SidebarContext);

  const [entity, setEntity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [header, setHeader] = useState("Usage");

  const fetchEntity = async () => {
    try {
      const response = await getProductById(id);
      console.log(response.data);
      setEntity(response.data);
    } catch (err) {
      console.error("Failed to fetch entity:", err);
      setError("Entity not found or unable to fetch details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEntity();
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h5">Loading...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h5" color="error">
          {error}
        </Typography>
        <Button variant="contained" onClick={() => window.history.back()}>
          Go Back
        </Button>
      </Container>
    );
  }

  const renderArrayField = (items, fieldName) =>
    items?.length > 0
      ? items
          .map((item) =>
            typeof item === "object" ? item[fieldName + "_name"] || "N/A" : item
          )
          .join(", ")
      : `No ${fieldName} Available`;

  const renderSubCategory = (subCategory) => {
    if (typeof subCategory === "object") {
      return subCategory.name || "N/A";
    }
    return `No Subcategory Available`;
  };

  return (
    <div className="layout">
      <Sidebar active={active} />
      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <div className="generic-details">
          <div className="flex flex-col md:flex-row gap-4 w-full">
            {/* Classification Card */}
            <div className="flex-1 w-full">
              {/* First Card */}
              <Card className="custom-card mt-20">
                <CardContent className="custom-card-content">
                  <div className="flex flex-col sm:flex-col md-flex-row lg:flex-row justify-between">
                    <div className="bg-divbg-100 p-1 rounded-sm">
                      <Typography variant="body1" fontWeight={200}>
                        Name: {entity.name || "N/A"}
                      </Typography>
                    </div>
                    <div className="bg-divbg-100 p-1 rounded-sm">
                      <Typography variant="body1" fontWeight={200}>
                        Unique ID: {entity.generic_product_code || "N/A"}
                      </Typography>
                    </div>
                  </div>
                  {entity.image && (
                    <div className="image-container">
                      <img
                        src={entity.image}
                        alt={entity.name || "Product Image"}
                        style={{
                          width: "100%",
                          height: "auto",
                          maxWidth: "300px",
                          borderRadius: "8px",
                          margin: "20px 0",
                        }}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>

              {/* Second Card */}

              <Card className="custom-card">
                <CardContent>
                  <Typography variant="h6" fontWeight={100} gutterBottom>
                    Classification
                  </Typography>
                  <hr />
                  <Data
                    title={"Family"}
                    content={
                      entity.sub_category.type.division.category.family?.name ||
                      "N/A"
                    }
                  />
                  <Data
                    title={"Category"}
                    content={
                      entity.sub_category.type.division.category.name || "N/A"
                    }
                  />
                  <Data
                    title={"Division"}
                    content={entity.sub_category.type.division.name || "N/A"}
                  />
                  <Data
                    title={"Type"}
                    content={entity.sub_category.type.name || "N/A"}
                  />
                  <Data
                    title={"Sub-category"}
                    content={renderSubCategory(entity.sub_category)}
                  />
                </CardContent>
              </Card>

              <Card className="custom-card">
                <CardContent className="custom-card-content">
                  <div>
                    <Typography variant="h6" fontWeight={100}>
                      Handling Warnings
                    </Typography>
                    <hr className="mt-2" />
                    <Typography
                      variant="body1"
                      fontWeight={100}
                      className="pt-4"
                    >
                      {entity.handling_warnings ||
                        "No Handling warning details available."}
                    </Typography>
                  </div>
                </CardContent>
              </Card>

              <Card className="custom-card">
                <CardContent className="custom-card-content">
                  <div>
                    <Typography variant="h6" fontWeight={100}>
                      Drug Interactions
                    </Typography>
                    <hr className="mt-2" />
                    <Typography
                      variant="body1"
                      fontWeight={100}
                      className="pt-4"
                    >
                      {entity.drug_interactions ||
                        "No Handling warning details available."}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Mechanism of Action Card */}
            <div className="flex-1 w-full">
              {/* First Card */}
              <Card className="custom-card overflow-x-auto md:mt-20">
                <CardContent className="custom-card-content">
                  <div className="flex whitespace-nowrap justify-center gap-8">
                    {/* Navigation Options */}
                    <div className="flex-shrink-0 ">
                      <Typography
                        variant="body1"
                        fontWeight={100}
                        className={`cursor-pointer ${
                          header === "Usage" ? "font-bold" : ""
                        }`}
                        onClick={() => setHeader("Usage")}
                      >
                        Usage
                      </Typography>
                      {header === "Usage" && (
                        <hr className="bg-text-100 h-0.5" />
                      )}
                    </div>

                    <div className="flex-shrink-0">
                      <Typography
                        variant="body1"
                        fontWeight={100}
                        className={`cursor-pointer ${
                          header === "Mechanism of actions" ? "font-bold" : ""
                        }`}
                        onClick={() => setHeader("Mechanism of actions")}
                      >
                        Mechanism of Actions
                      </Typography>
                      {header === "Mechanism of actions" && (
                        <hr className="bg-text-100 h-0.5" />
                      )}
                    </div>

                    <div className="flex-shrink-0">
                      <Typography
                        variant="body1"
                        fontWeight={100}
                        className={`cursor-pointer ${
                          header === "Cautions" ? "font-bold" : ""
                        }`}
                        onClick={() => setHeader("Cautions")}
                      >
                        Cautions
                      </Typography>
                      {header === "Cautions" && (
                        <hr className="bg-text-100 h-0.5" />
                      )}
                    </div>
                  </div>
                </CardContent>

                {/* Details Card */}
                <div className="details-card flex-shrink-0 min-w-full">
                  <CardContent>
                    {header === "Usage" && (
                      <div>
                        <Typography variant="h6" fontWeight={100}>
                          Usage Details
                        </Typography>
                        <hr className="m-2" />

                        <Typography variant="body1" fontWeight={100}>
                          {entity.use || "No Usage details available."}
                        </Typography>
                      </div>
                    )}

                    {header === "Mechanism of actions" && (
                      <div>
                        <Typography variant="h6" fontWeight={100}>
                          Mechanism of Actions
                        </Typography>
                        <hr className="m-2" />
                        <Typography variant="body1" fontWeight={100}>
                          {entity.mechanism_of_action ||
                            "No Mechanism details available."}
                        </Typography>
                      </div>
                    )}

                    {header === "Cautions" && (
                      <div>
                        <Typography variant="h6" fontWeight={100}>
                          Cautions
                        </Typography>
                        <hr className="m-2" />
                        <Typography variant="body1" fontWeight={100}>
                          {entity.cautions || "No Cautions details available."}
                        </Typography>
                      </div>
                    )}
                  </CardContent>
                </div>
              </Card>

              <Card className="custom-card">
                <CardContent className="custom-card-content">
                  <div>
                    <Typography variant="h6" fontWeight={100}>
                      Detailing/Dosing
                    </Typography>
                    <hr className="mt-2" />
                    {entity.dosage && entity.dosage.length > 0 ? (
                      entity.dosage.map((item, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <Data
                            title={"Dosage Name"}
                            content={
                              item.dosage_name || "Safety name not available."
                            }
                          />
                          <Data
                            title={"Comment"}
                            content={item.comment || "No comment available."}
                          />
                        </div>
                      ))
                    ) : (
                      <Typography
                        variant="body1"
                        fontWeight={100}
                        className="pt-4"
                      >
                        No Dosage Information Available
                      </Typography>
                    )}
                  </div>
                </CardContent>
              </Card>
              <Card className="custom-card">
                <CardContent className="custom-card-content">
                  <div>
                    <Typography variant="h6" fontWeight={100}>
                      Safety
                    </Typography>
                    <hr className="mt-2" />
                    {entity.safety && entity.safety.length > 0 ? (
                      entity.safety.map((item, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <Data
                            title={"Dosage Name"}
                            content={
                              item.safety_name || "Safety name not available."
                            }
                          />
                          <Data
                            title={"Comment"}
                            content={item.comment || "No comment available."}
                          />
                        </div>
                      ))
                    ) : (
                      <Typography
                        variant="body1"
                        fontWeight={100}
                        className="pt-4"
                      >
                        No Safety Information Available
                      </Typography>
                    )}
                  </div>
                </CardContent>
              </Card>

              <Card className="custom-card">
                <CardContent className="custom-card-content">
                  <div>
                    <Typography variant="h6" fontWeight={100}>
                      Adverse Reactions
                    </Typography>
                    <hr className="mt-2" />
                    <Typography
                      variant="body1"
                      fontWeight={100}
                      className="pt-4"
                    >
                      {entity.adverse_reactions ||
                        "No Adverse reaction details available."}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericDetails;
