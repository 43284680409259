import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Container,
  Grid,
} from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import { SidebarContext } from "../Sidebar/SidebarContext";
import { getItem } from "../../services/listItemService";
import "./ItemDetails.css";

const ItemDetails = ({ active }) => {
  const { id } = useParams();
  const { expanded } = useContext(SidebarContext);

  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const response = await getItem(id);
        setItemDetails(response.data);
      } catch (err) {
        setError("Failed to load item details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="lg" className="centered-container">
        <Typography variant="h5">Loading...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" className="centered-container">
        <Typography variant="h5" color="error">
          {error}
        </Typography>
        <Button variant="contained" onClick={() => window.history.back()}>
          Go Back
        </Button>
      </Container>
    );
  }

  return (
    <div className="layout">
      <Sidebar active={active} />
      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Box className="header-box">
          <Typography variant="h4" className="header-text">
            Item Details
          </Typography>
        </Box>
        <Grid container spacing={3} className="item-details-container">
          {/* Basic Information Card */}
          <Grid item xs={12}>
            <Card className="custom-card">
              <CardContent>
                <Typography variant="h6" className="header-text">
                  Basic Information
                </Typography>
                <hr />
                <Typography variant="body1">
                  <strong>Item Code:</strong> {itemDetails.item_code || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Product Code:</strong>{" "}
                  {itemDetails.product_details?.product_code || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Product Name:</strong>{" "}
                  {itemDetails.product_details?.product_name || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Price:</strong> {itemDetails.product_price || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Presentation number:</strong>{" "}
                  {itemDetails.presentation_number || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Presentation Unit:</strong>{" "}
                  {itemDetails.presentation_unit_details?.name || "N/A"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Batch and Supplier Information */}
          <Grid item xs={12}>
            <Card className="custom-card">
              <CardContent>
                <Typography variant="h6" className="header-text">
                  Batch & Supplier Information
                </Typography>
                <hr />
                <Typography variant="body1">
                  <strong>Batch Number:</strong> {itemDetails.batch_no || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Supplier:</strong> {itemDetails.supplier || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Expiration Date:</strong>{" "}
                  {itemDetails.expiration_date || "N/A"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ItemDetails;
