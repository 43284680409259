import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";
import {
  createItem,
  getProductCodeSuggestion,
  updateItem,
  getItem,
} from "../../services/listItemService";
import { toast } from "react-toastify";
import { getPresentationUnit } from "../../services/productService";

const ItemForm = ({ active }) => {
  const { expanded } = useContext(SidebarContext);

  const navigate = useNavigate();
  const { itemId } = useParams();
  const [unit, setUnit] = useState([]);
  const [item, setItem] = useState({
    product: "",
    item_name: "",
    product_price: "",
    batch_no: "",
    supplier: "",
    expiration_date: "",
    presentation_number: "",
    presentation_unit: "",
    visibility: "",
  });
  const [productSuggestions, setProductSuggestions] = useState([]);

  // Fetch item details if editing an existing item
  useEffect(() => {
    getPresentationUnit()
      .then((response) => {
        setUnit(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Units:", error);
      });
    if (itemId) {
      getItem(itemId)
        .then((response) => {
          setItem({
            ...response.data,
            product_code: response.data.product_details?.product_code || "", // Ensure product_code is set
            presentation_number: response.data.presentation_number || "",
            presentation_unit: response.data.presentation_unit || "",
          });
          console.log(response.data);
        })
        .catch((error) => console.error("Error fetching item:", error));
    }
  }, [itemId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };
  // const handleImageChange = (e) => {
  //   setItem({ ...item, image: e.target.files[0] });
  // };

  const handleProductSearch = async (event) => {
    const query = event.target.value;
    setItem((prevItem) => ({
      ...prevItem,
      product_code: query,
    }));

    if (query.length >= 1) {
      try {
        const response = await getProductCodeSuggestion(query);
        setProductSuggestions(response.data);
      } catch (error) {
        console.error("Error fetching product suggestions:", error);
      }
    } else {
      setProductSuggestions([]);
    }
  };
  const handleDropdownChange = (field) => (e) => {
    setItem((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (itemId) {
        // Update existing item
        await updateItem(itemId, item);
        // alert("Item updated successfully!");
        toast.success("Item updated successfully!");
      } else {
        // Create a new item
        await createItem(item);
        // alert("Item added successfully!");
        toast.success("Item added successfully!");
      }
      navigate("/list-items");
    } catch (error) {
      console.error("Error saving item:", error);
      toast.error("Failed to save item. Please try again.");
    }
  };

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`container ${expanded ? "expanded" : "collapsed"}`}>
        <Typography variant="h4" gutterBottom className="header">
          {itemId ? "Edit Item" : "Add Item"}
        </Typography>
        <Card elevation={3} className="card">
          <CardContent>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <label>Product Code</label>
                  <Autocomplete
                    options={productSuggestions}
                    getOptionLabel={(option) => option.product_code || ""}
                    value={
                      productSuggestions.find(
                        (option) => option.product_code === item.product_code
                      ) || { product_code: item.product_code }
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.product_code === value.product_code
                    }
                    onInputChange={(event, newInputValue) => {
                      handleProductSearch({ target: { value: newInputValue } });
                    }}
                    onChange={(event, newValue) => {
                      setItem((prevItem) => ({
                        ...prevItem,
                        product_code: newValue?.product_code || "",
                        product: newValue?.id || "",
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="product"
                        variant="outlined"
                        fullWidth
                        multiline
                        placeholder="Search Product Code"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Item Name</label>
                  <TextField
                    fullWidth
                    name="item_name"
                    value={item.item_name}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <label
                    className="generic-form-label"
                    style={{ fontSize: "14px" }} // Adjust the font size
                  >
                    {" "}
                    *Image
                  </label>
                  <input
                    name="product_image"
                    type="file"
                    onChange={handleImageChange}
                    className="generic-form-file-input"
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <label>₹ Product Price</label>
                  <TextField
                    fullWidth
                    name="product_price"
                    value={item.product_price}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Batch Number</label>
                  <TextField
                    fullWidth
                    name="batch_no"
                    value={item.batch_no}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Supplier</label>
                  <TextField
                    fullWidth
                    name="supplier"
                    value={item.supplier}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Expiration Date</label>
                  <TextField
                    fullWidth
                    name="expiration_date"
                    type="date"
                    value={item.expiration_date}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Presentation Number</label>
                  <TextField
                    fullWidth
                    name="presentation_number"
                    value={item.presentation_number}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Unit</label>
                  <Select
                    fullWidth
                    name="presentation_unit"
                    value={item.presentation_unit} // This should be a single value
                    onChange={handleDropdownChange("presentation_unit")}
                    variant="outlined"
                  >
                    <MenuItem value="">Select Unit</MenuItem>
                    {unit.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Visibility</label>
                  <Select
                    fullWidth
                    name="visibility"
                    value={item.visibility}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <div className="button-group flex gap-3 mt-4">
                <button
                  onClick={() => navigate("/list-items")}
                  className="cancelButton"
                >
                  Cancel
                </button>
                <button type="submit" className="saveButton">
                  Save Item
                </button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ItemForm;
