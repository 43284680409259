import axiosInstance from "./axiosInstance";

const getFamilies = () => {
  return axiosInstance.get("families/");
};

const deleteFamilies = (id) => {
  return axiosInstance.delete(`product_family_create/${id}/`);
};

const getCategories = () => {
  return axiosInstance.get("categories/");
};
const deleteCategories = (id) => {
  return axiosInstance.delete(`product_category_create/${id}/`);
};

const getDivisions = () => {
  return axiosInstance.get("divisions/");
};

const deleteDivisions = (id) => {
  return axiosInstance.delete(`product_division_create/${id}/`);
};

const getTypes = () => {
  return axiosInstance.get("types/");
};

const deleteTypes = (id) => {
  return axiosInstance.delete(`product_type_create/${id}/`);
};

const getSubCategories = () => {
  return axiosInstance.get("sub-categories/");
};

const deleteSubCategories = (id) => {
  return axiosInstance.delete(`product_subcategory_create/${id}/`);
};

const getChoices = async () => {
  const [families, categories, divisions, types, subCategories] =
    await Promise.all([
      getFamilies(),
      getCategories(),
      getDivisions(),
      getTypes(),
      getSubCategories(),
    ]);

  return {
    families: families.data,
    categories: categories.data,
    divisions: divisions.data,
    types: types.data,
    subCategories: subCategories.data,
  };
};

const createFamily = (families) => {
  return axiosInstance.post("product_family_create/", families);
};

const createCategory = (categories) => {
  return axiosInstance.post("product_category_create/", categories);
};

const createDivision = (divisions) => {
  return axiosInstance.post("product_division_create/", divisions);
};

const createType = (types) => {
  return axiosInstance.post("product_type_create/", types);
};

const createSubCategory = (subcategories) => {
  return axiosInstance.post("product_subcategory_create/", subcategories);
};

const updateChoice = (id, choice) => {
  const endpointMap = {
    families: `product_family_create/${id}/`,
    categories: `product_category_create/${id}/`,
    divisions: `product_division_create/${id}/`,
    types: `product_type_create/${id}/`,
    subCategories: `product_subcategory_create/${id}/`,
  };
  return axiosInstance.put(endpointMap[choice.field_name], choice);
};

const deleteChoice = (id, fieldName) => {
  const endpointMap = {
    families: `product_family_create/${id}/`,
    categories: `product_category_create/${id}/`,
    divisions: `product_division_create/${id}/`,
    types: `product_type_create/${id}/`,
    subCategories: `product_subcategory_create/${id}/`,
  };
  return axiosInstance.delete(endpointMap[fieldName]);
};

const createProduct = (product) => {
  return axiosInstance.post("products/", product);
};

const uploadProductImage = async (id, formData) => {
  return axiosInstance.post(`upload_image/${id}/`, formData);
};

const getProducts = () => {
  return axiosInstance.get("generic_products_list/");
};

const getProductById = (id) => {
  return axiosInstance.get(`products/${id}/`);
};

const updateProduct = (id, product) => {
  return axiosInstance.patch(`products/${id}/`, product);
};

const deleteProduct = (id) => {
  return axiosInstance.delete(`products/${id}/`);
};

const getDashboardCount = () => {
  return axiosInstance.get("dashboard/");
};

const getDosages = () => {
  return axiosInstance.get("dosage/");
};

const createDosage = (dosage) => {
  return axiosInstance.post("dosage/", dosage);
};

const getSafeties = (option) => {
  return axiosInstance.post("safety/", { name: option });
};

const createSafety = (safety) => {
  return axiosInstance.post("safety/", safety);
};
const getSafetyList = () => {
  return axiosInstance.get("safeties_list/");
};
const getDosageList = () => {
  return axiosInstance.get("dosages_list/");
};

const exportGenericProducts = () => {
  return axiosInstance.get("generic-products/export/");
}

export {
  getChoices,
  createFamily,
  getFamilies,
  deleteFamilies,
  createCategory,
  getCategories,
  deleteCategories,
  createDivision,
  getDivisions,
  deleteDivisions,
  createType,
  getTypes,
  deleteTypes,
  createSubCategory,
  getSubCategories,
  deleteSubCategories,
  updateChoice,
  deleteChoice,
  createProduct,
  getProducts,
  getProductById,
  updateProduct,
  deleteProduct,
  getDashboardCount,
  getDosages,
  createDosage,
  getSafeties,
  createSafety,
  getDosageList,
  getSafetyList,
  uploadProductImage,
  exportGenericProducts,
};
