import React, { useState, useEffect, useContext } from "react";
import {
  getProducts as fetchProducts,
  deleteProduct as removeProduct,
  exportGenericProducts,
} from "../../services/genericService";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Inventory,
  Category,
} from "@mui/icons-material";
import "./GenericList.css";
import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";
import { toast } from "react-toastify";

const GenericList = ({ active }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKey, setFilterKey] = useState("name"); // Updated state for filter
  const navigate = useNavigate();
  const { expanded } = useContext(SidebarContext);
  const [sortKey, setSortKey] = useState();

  useEffect(() => {
    fetchProductsData();
  }, []);

  const fetchProductsData = async () => {
    try {
      const response = await fetchProducts();
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // const handleDelete = async (id) => {
  //   try {
  //     await removeProduct(id);
  //     fetchProductsData();
  //     toast.success("Generic Product deleted sucessfully!");
  //   } catch (error) {
  //     console.error("Error deleting product:", error);
  //   }
  // };

  const handleDelete = async (id) => {
    const confirmDeleteToast = () => {
      const toastId = toast.warn(
        <div>
          <p>Are you sure you want to delete this item?</p>
          <div
            style={{ display: "flex", justifyContent: "space-around", gap: 10 }}
          >
            <button
              onClick={async () => {
                toast.dismiss(toastId); // Dismiss the confirmation toast
                await deleteProductAction(id); // Proceed with deletion
              }}
              style={{
                backgroundColor: "#f44336",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
              }}
            >
              Yes
            </button>
            <button
              onClick={() => toast.dismiss(toastId)} // Cancel deletion
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
              }}
            >
              No
            </button>
          </div>
        </div>,
        {
          autoClose: false, // Keep the toast open until the user interacts
          closeOnClick: false,
        }
      );
    };

    const deleteProductAction = async (id) => {
      try {
        await removeProduct(id); // Call the API to delete the product
        fetchProductsData(); // Refresh the product list
        toast.success("Generic Product deleted successfully!", {
          autoClose: 3000,
          position: "top-right",
        });
      } catch (error) {
        console.error("Error deleting product:", error);
        toast.error("Failed to delete the product. Please try again.", {
          autoClose: 5000,
          position: "top-right",
        });
      }
    };

    confirmDeleteToast(); // Trigger the confirmation toast
  };

  const handleEdit = (product) => {
    navigate(`/add-product/${product.id}`, { state: { product } });
  };

  const handleRowClick = (product) => {
    navigate(`/details/${product.id}`, { state: { entity: product } });
  };

  const handleExportCSV = async () => {
    try {
      const response = await exportGenericProducts();
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "generic_products.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting CSV:", error);
    }
  };

  // Filtering and searching logic
  const filteredProducts = products.filter((product) =>
    product[filterKey]?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalGenericProducts = products.length;
  const totalProducts = products.filter(
    (product) => product.category === "Generic"
  ).length;

  return (
    <div className="layout">
      <Sidebar active={active} />
      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Box className="header-box">
          <Typography
            sx={{
              fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.8rem" },
              fontWeight: "200",
            }}
            className="header-title mt-2"
          >
            Generic List
          </Typography>
          <div>
            <button
              variant="contained"
              className="export-button"
              onClick={handleExportCSV}
            >
              Export CSV
            </button>
            <button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/add-product")}
              className="add-button"
            >
              + Add Generic
            </button>
          </div>
        </Box>

        <TableContainer component={Paper} className="table-container">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="filter-box "
          >
            <Grid item>
              <div className="total-container">
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#5542F6" }}
                >
                  Total ({totalGenericProducts})
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <input
                placeholder={`Search by ${filterKey}`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </Grid>
            <Grid item className="sort-select-container">
              <select
                value={filterKey}
                onChange={(e) => setFilterKey(e.target.value)} // Update the filter key
                className="sort-select"
              >
                <option value="">Filter by</option>
                <option value="name">Name</option>
                <option value="family">Family</option>
                <option value="category">Category</option>
                <option value="division">Division</option>
                <option value="type">Type</option>
                <option value="sub_category">Sub-Category</option>
              </select>
            </Grid>
          </Grid>

          <Table className="border-none">
            <TableHead className="table-head ">
              <TableRow>
                <TableCell className="table-cell-header">ID</TableCell>
                <TableCell className="table-cell-header">Name</TableCell>
                <TableCell className="table-cell-header">Family</TableCell>
                <TableCell className="table-cell-header">Category</TableCell>
                <TableCell className="table-cell-header">Division</TableCell>
                <TableCell className="table-cell-header">Type</TableCell>
                <TableCell className="table-cell-header">
                  Sub-Category
                </TableCell>
                <TableCell className="table-cell-header">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="border-none">
              {filteredProducts.map((product, index) => (
                <TableRow
                  key={product.id}
                  hover
                  onClick={() => handleRowClick(product)}
                >
                  <TableCell className="border-none">{index + 1}</TableCell>
                  <TableCell className="border-none">{product.name}</TableCell>
                  <TableCell className="border-none">
                    {product.family ? product.family : "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {product.category ? product.category : "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {product.division ? product.division : "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {product.type ? product.type : "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    {product.sub_category ? product.sub_category : "N/A"}
                  </TableCell>
                  <TableCell className="border-none">
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(product);
                        }}
                        className="edit-button"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(product.id);
                        }}
                        className="delete-button"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default GenericList;
